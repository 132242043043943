import * as style from "./SmallPlanCard.module.scss"

import React from "react"
import { numberWithCommas } from "../../plugins/common"
import BenefitIcon from "./BenefitIcon"
import { getBackgroundImage } from "../../plugins/common"
import { staticText } from "../../enumerator/staticText"

const SmallPlanCard = props => {
  const {
    plan,
    isCompare,
    forwardRef,
    location,
    additionalOptionsLabel,
    currency,
    premiumDivideBy,
    labels,
  } = props

  const { perMonthLabel, perYearLabel } = labels

  const companyLogo = location.origin + getBackgroundImage(plan.logo)
  // const usdLabel = staticText.USD

  return (
    <div
      className={`${style.smallCardPlan} soft-corners container ${
        isCompare ? "mx-3" : ""
      }`}
    >
      <div className={`bg-mint ${style.smallCardPlanInner}`} ref={forwardRef}>
        {/* logo */}
        <div className={`${style.logoWrapper} text-center`}>
          {/* Using <img> because the html-to-image doesn't support gatsby image */}
          <img
            src={companyLogo}
            className={`${style.logo} flex-1`}
            alt="alea"
          />
        </div>

        <div className={`${style.planName}`}>
          <div className={`${style.title}`}>{plan.planName}</div>
          <div>{plan.coverageLevel}</div>
        </div>

        <div className={`${style.benefits}`}>
          {plan.benefits.map((benef, index) => {
            return (
              (benef.included || isCompare) && (
                <div className={`row`} key={index}>
                  <div className={`col-2 `}>
                    <BenefitIcon
                      id={benef.pipedriveId}
                      included={benef.included}
                      inlineStyle={{ width: "30px", verticleAlign: "middle" }}
                      isLocal={true}
                    />
                  </div>
                  <div className={`col-9 ${style.benefitName}`}>
                    {benef.name}
                  </div>
                </div>
              )
            )
          })}
        </div>
        {plan.additional.length ? (
          <div className={`${style.additional}`}>
            {additionalOptionsLabel}
            <ul>
              {plan.additional.map(option => (
                <li key={option}>{option}</li>
              ))}
            </ul>
          </div>
        ) : (
          ""
        )}
        <div className={`${style.premiums}`}>
          {/* {usdLabel === staticText.USD ? usdLabel + " " : ""} */}
          {currency.symbol + " "}
          <span className={style.price}>
            {/* {numberWithCommas(plan.annualPremium)} */}
            {numberWithCommas(Math.round(plan.annualPremium / premiumDivideBy))}
          </span>
          {premiumDivideBy === 12 ? perMonthLabel : perYearLabel}
          {/* {usdLabel !== staticText.USD ? usdLabel : ""} */}
        </div>
      </div>
    </div>
  )
}
export default SmallPlanCard

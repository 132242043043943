// extracted by mini-css-extract-plugin
export var additional = "SmallPlanCard-module--additional--bhfFD";
export var alertRed = "SmallPlanCard-module--alert-red--NvoUI";
export var benefitIcon = "SmallPlanCard-module--benefitIcon--MGgs+";
export var benefitName = "SmallPlanCard-module--benefitName--oEOGr";
export var benefits = "SmallPlanCard-module--benefits--xMjRq";
export var bgBarGrey = "SmallPlanCard-module--bg-bar-grey--mCSIm";
export var bgBeige = "SmallPlanCard-module--bg-beige--YcHgX";
export var bgCopper = "SmallPlanCard-module--bg-copper--LdUdl";
export var bgDaffodil = "SmallPlanCard-module--bg-daffodil--eqx2I";
export var bgGreyText = "SmallPlanCard-module--bg-grey-text--1bzXx";
export var bgMing = "SmallPlanCard-module--bg-ming--fgnDi";
export var bgMint = "SmallPlanCard-module--bg-mint--3QCf7";
export var bgRed = "SmallPlanCard-module--bg-red--1O2At";
export var bgRedTrans = "SmallPlanCard-module--bg-red-trans--w+LmC";
export var bgSand = "SmallPlanCard-module--bg-sand--aL3-O";
export var bgSoftSun = "SmallPlanCard-module--bg-softSun--rLyJ+";
export var bgTeal = "SmallPlanCard-module--bg-teal--NFFjI";
export var bgWhite = "SmallPlanCard-module--bg-white--0njmg";
export var bgWomenSharp = "SmallPlanCard-module--bg-women-sharp--33Xrr";
export var blackText = "SmallPlanCard-module--black-text--5A55w";
export var copper = "SmallPlanCard-module--copper--TRBIb";
export var corners = "SmallPlanCard-module--corners--jHRIj";
export var cursorNormal = "SmallPlanCard-module--cursor-normal--WaOfn";
export var cursorPointer = "SmallPlanCard-module--cursor-pointer--Yssb1";
export var dUnset = "SmallPlanCard-module--d-unset--h8355";
export var darkGrey = "SmallPlanCard-module--dark-grey--BkxLV";
export var dash = "SmallPlanCard-module--dash--ldGGG";
export var flex = "SmallPlanCard-module--flex--wiZb8";
export var flex1 = "SmallPlanCard-module--flex-1--y6FNM";
export var fontSize12 = "SmallPlanCard-module--font-size-12--aTJjU";
export var fontSize20 = "SmallPlanCard-module--font-size-20--kY2Q4";
export var greyPlaceholder = "SmallPlanCard-module--grey-placeholder--Gso4E";
export var greyText = "SmallPlanCard-module--grey-text--HQIdY";
export var h1sizing = "SmallPlanCard-module--h1sizing--buDgt";
export var h2sizing = "SmallPlanCard-module--h2sizing--xCUFV";
export var hidden = "SmallPlanCard-module--hidden--dxBRM";
export var imageAni = "SmallPlanCard-module--imageAni--7nZ5B";
export var imageZoom = "SmallPlanCard-module--imageZoom--iiRl8";
export var imageZoomGatsby = "SmallPlanCard-module--imageZoomGatsby--g9la2";
export var include = "SmallPlanCard-module--include--5wVCu";
export var italic = "SmallPlanCard-module--italic--uIdr8";
export var lightGrey = "SmallPlanCard-module--light-grey--9yzd2";
export var logo = "SmallPlanCard-module--logo--1DaSG";
export var logoWrapper = "SmallPlanCard-module--logoWrapper--RIMKP";
export var lora = "SmallPlanCard-module--lora--YEbb4";
export var loraBold = "SmallPlanCard-module--lora-Bold--3fEc4";
export var loraBoldItalic = "SmallPlanCard-module--lora-BoldItalic--DJU3W";
export var loraMedium = "SmallPlanCard-module--lora-Medium--U775Q";
export var loraSemiBold = "SmallPlanCard-module--lora-SemiBold--CIpfv";
export var main = "SmallPlanCard-module--main--GLGNz";
export var ming = "SmallPlanCard-module--ming--Mjxmk";
export var mingHover2 = "SmallPlanCard-module--ming-hover-2--hWIEm";
export var modalOpen = "SmallPlanCard-module--modal-open--3GBln";
export var noUnderline = "SmallPlanCard-module--no-underline--Il6u7";
export var openSans = "SmallPlanCard-module--openSans--6zZZ+";
export var openSansBold = "SmallPlanCard-module--openSans-Bold--qJcwv";
export var openSansSemiBold = "SmallPlanCard-module--openSans-SemiBold--oi9ij";
export var planName = "SmallPlanCard-module--planName--EEpd2";
export var premiums = "SmallPlanCard-module--premiums--rCwx4";
export var price = "SmallPlanCard-module--price--naHH2";
export var quotation = "SmallPlanCard-module--quotation--FBrgL";
export var round = "SmallPlanCard-module--round--YkXLN";
export var roundCorners = "SmallPlanCard-module--round-corners--KDPUU";
export var smallCardPlan = "SmallPlanCard-module--smallCardPlan--JAjHp";
export var smallCardPlanInner = "SmallPlanCard-module--smallCardPlanInner--V5+2W";
export var softCorners = "SmallPlanCard-module--soft-corners--aXsuN";
export var softShadows = "SmallPlanCard-module--soft-shadows--oHuPI";
export var softerCorners = "SmallPlanCard-module--softer-corners--clW-V";
export var softyCorners = "SmallPlanCard-module--softy-corners--fGu7U";
export var tableShadow = "SmallPlanCard-module--table-shadow--E7Yrd";
export var teal = "SmallPlanCard-module--teal--9WJeZ";
export var title = "SmallPlanCard-module--title--WwtjV";
export var topNavShadow = "SmallPlanCard-module--top-nav-shadow--7wklX";
export var uppercase = "SmallPlanCard-module--uppercase--5xE0L";
export var wFull = "SmallPlanCard-module--w-full--8f2By";
export var white = "SmallPlanCard-module--white--EtfXm";
export var womenRed = "SmallPlanCard-module--women-red--kXifu";
export var womenSharp = "SmallPlanCard-module--women-sharp--N+v1p";
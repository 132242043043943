import * as style from "./Search.module.scss"

import React, { useState } from "react"
import Button from "../Button"
// import QuotationFilter from "./Filter"
import BigPlanCard from "./BigPlanCard"
import Skeleton from "../Skeleton"
import {
  mdiSwapHorizontal,
  mdiSwapVertical,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
} from "@mdi/js"
import Icon from "@mdi/react"
// import { quotationType } from "../../enumerator/quotationType"
import Dropdown from "../Dropdown"
// import QuotationAgeFilter from "./AgeFilter"
import DropdownV2 from "./DropdownV2"
import Switch from "../common/Switch"
import AddPerson from "./AddPerson"
import { gender } from "../../enumerator/gender"
import { handleEnterPress, isEmailValid } from "../../plugins/common"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
// import CompareBar from "./CompareBar"

const StepSearch = props => {
  const {
    // defaultOptions,
    // hideAgeFilter,
    updateMobileFilter,
    proceedFurther,
    loading,
    plansSearched,
    filterGetter,
    filterSetter,
    filterLabels,
    loadMorePlans,
    updateCompare,
    plansForComparing,
    updateDetailCard,
    labels,
    openCalendly,
    currency,
    currencyList,
    setCurrency,
    countryList,
    goToQuote,
    // updateSnackBar,
    ageList,
    lang,
    submitEmail,
  } = props

  const {
    insuranceCompany,
    // budget,
    benefits,
    // cfe,
    age,
    sort,
    limit,
    mainGender,
    premiumDivideBy,
    additionInsurers,
    email,
    hasEmail,
    errorMessage,
  } = filterGetter
  const {
    setInsuranceCompany,
    setBenefits,
    // setCfe,
    setAge,
    setSort,
    setMainGender,
    setPremiumDivideBy,
    setAdditionInsurers,
    setEmail,
    setErrorMessage,
  } = filterSetter
  const {
    insuranceCompanyLabel,
    // budgetLabel,
    benefitsLabel,
    // yourAgeLabel,
    ageLabel,
    genderLabel,
    // addPersonLabel,
    locationLabel,
    monthlyLabel,
    yearlyLabel,
    lowToHigh,
    highToLow,
    priceLabel,
    hongKongLabel,
  } = filterLabels

  const {
    disclaimer,
    premiumsLabel,
    filterLabel,
    showMoreLabel,
    noResultLabel,
    //applyLabel,
    // planLabel,
    // compareLabel,
    // comparePlansLabel,
    getAdviceCta,
    // seeMoreLabel,
    // seeLessLabel,
    // selectToCompareLabel,
    selectAllLabel,
    clearAllLabel,
    emailPh,
    resultEmailTitle,
    resultEmailSubtitle,
    submitLabel,
    emailTnc,
    invalidTncMsg,
    invalidEmailMsg,
  } = labels

  // const preselectedAge = defaultOptions.age

  // const selectToCompareLabel = "Select to compare"
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  const isPlanComparing = plan =>
    plansForComparing.length
      ? plansForComparing.find(p => p.id === plan.id)
      : false

  // const comparePlans = () =>
  //   proceedFurther(plansForComparing, quotationType.COMPARE)

  // const applyCheckedPlan = () =>
  //   proceedFurther(...plansForComparing, quotationType.APPLY)

  const sortOptions = [
    {
      name: highToLow,
      value: -1,
      shortName: highToLow,
      code: -1,
    },
    {
      name: lowToHigh,
      value: 1,
      shortName: lowToHigh,
      code: 1,
    },
  ]
  const premiumByOptions = [
    {
      name: monthlyLabel,
      value: 12,
      shortName: monthlyLabel,
      code: 12,
    },
    {
      name: yearlyLabel,
      value: 1,
      shortName: yearlyLabel,
      code: 1,
    },
  ]

  const updateSorting = item => setSort(item.value)
  const updateDivideBy = item => setPremiumDivideBy(item.value)

  const currenyOptions = currencyList.map(cur => {
    return {
      name: cur.name,
      shortName: cur.name,
      value: cur,
      code: cur.name,
    }
  })

  const updateCurrency = c => {
    const newCur = currencyList.find(cur => cur.name === c.name)
    setCurrency(newCur)
  }

  const getNextCurrency = () => {
    const index = currencyList.findIndex(cur => cur.name === currency.name)
    const newIndex =
      (index === 0 || index) && index < currencyList.length - 1 ? index + 1 : 0
    return currencyList[newIndex]
  }

  const switchCurrency = () => {
    const newCur = getNextCurrency()
    setCurrency(newCur)
  }

  const switchSorting = () => {
    setSort(sort * -1)
  }

  const checkEmail = () => {
    if (!agreeTerms) {
      setTermsError(true)
      setErrorMessage(invalidTncMsg)
      return
    }
    if (!isEmailValid(email)) {
      setEmailError(true)
      setErrorMessage(invalidEmailMsg)
      return
    }

    setErrorMessage("")
    setTermsError(false)
    submitEmail()
  }

  return (
    <div
      className={`${style.quotationSearch} container position-relative px-0`}
    >
      <div
        className={`d-lg-none d-block mt-3 lora-Bold text-end ${style.mobileFilter}`}
      >
        <Button
          type="primaryCorners"
          text={filterLabel}
          event={updateMobileFilter}
        />
      </div>
      <div
        className={`d-lg-none d-flex px-0 ${style.optionsRow} my-4 justify-content-between`}
      >
        {/* <Button
          type="primary"
          text={sort === 1 ? lowToHigh : highToLow}
          event={switchSorting}
        /> */}
        <Button type="secondary" event={switchSorting}>
          <Icon
            // className={`position-absolute ${style.closeBtn}`}
            path={mdiSwapVertical}
            size={0.8}
          />{" "}
          {priceLabel}
        </Button>
        {!currencyList.length ? (
          ""
        ) : (
          <Button
            type="secondary"
            // text={}
            event={switchCurrency}
          >
            {currency.name}
            <Icon
              // className={`position-absolute ${style.closeBtn}`}
              path={mdiSwapHorizontal}
              size={0.8}
            />
            {getNextCurrency().name}
          </Button>
        )}
      </div>
      <div
        className={`justify-content-between mb-5 ${style.filterRow} d-lg-flex d-none`}
      >
        <div className={``}>
          <div className={`${style.filterLabel} mb-2 lora-Bold`}>
            {ageLabel}
          </div>
          <div className={``}>
            <DropdownV2
              options={ageList}
              selected={age}
              setSelected={setAge}
              labels={filterLabels}
            />
          </div>
        </div>
        <div className={``}>
          <div className={`${style.filterLabel} mb-2 lora-Bold`}>
            {genderLabel}
          </div>
          <div className={``}>
            <Switch
              optionA={{ name: filterLabels.femaleLabel, value: gender.female }}
              optionB={{ name: filterLabels.maleLabel, value: gender.male }}
              selected={mainGender}
              setSelected={setMainGender}
            />
          </div>
        </div>
        <div className={`align-self-end`}>
          <div className={``}>
            <AddPerson
              labels={filterLabels}
              ageList={ageList}
              additionInsurers={additionInsurers}
              setAdditionInsurers={setAdditionInsurers}
            />
          </div>
        </div>
        <div className={``}>
          <div className={`${style.filterLabel} mb-2 lora-Bold`}>
            {benefitsLabel}
          </div>
          <div className={``}>
            <DropdownV2
              options={benefits}
              updateOptions={setBenefits}
              selectAllLabel={selectAllLabel}
              clearAllLabel={clearAllLabel}
              multiSelect={true}
              labels={filterLabels}
            />
          </div>
        </div>
        <div className={``}>
          <div className={`${style.filterLabel} mb-2 lora-Bold`}>
            {insuranceCompanyLabel}
          </div>
          <div className={``}>
            <DropdownV2
              options={insuranceCompany}
              updateOptions={setInsuranceCompany}
              selectAllLabel={selectAllLabel}
              clearAllLabel={clearAllLabel}
              multiSelect={true}
              labels={filterLabels}
            />
          </div>
        </div>
        <div className={``}>
          <div className={`${style.filterLabel} mb-2 lora-Bold`}>
            {locationLabel}
          </div>
          <div className={``}>
            <DropdownV2
              options={countryList}
              selected={hongKongLabel}
              setSelected={goToQuote}
              labels={filterLabels}
              hideTriangle={true}
            />
          </div>
        </div>
      </div>
      {!hasEmail ? (
        <div className={`text-center d-block ${style.emailInput}`}>
          <h2 className={`ming mb-2`}>{resultEmailTitle}</h2>
          <p className={`ming mb-4`}>{resultEmailSubtitle}</p>
          <div>
            <input
              type="email"
              className={`${style.quotation} ${emailError ? style.error : ""}`}
              placeholder={emailPh}
              value={email}
              onKeyPress={e => handleEnterPress(e, checkEmail)}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div
            className={`${
              !agreeTerms && termsError ? "alert-red" : "ming"
            } flex justify-content-center align-items-center mt-3 ${
              errorMessage ? "" : "mb-4"
            }`}
            tabIndex={-1}
            onClick={() => setAgreeTerms(!agreeTerms)}
            onKeyDown={() => setAgreeTerms(!agreeTerms)}
            role="button"
          >
            <Icon
              className={`checkbox me-2`}
              path={agreeTerms ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
              size={1}
            />
            <CustomMarkdown html={emailTnc} classnames="" color="copper" />
          </div>
          {errorMessage && (
            <div className={`mt-2 alert-red openSans-Bold`}>{errorMessage}</div>
          )}
          <Button
            type="primary"
            className={`mt-2`}
            text={submitLabel}
            event={checkEmail}
          />
        </div>
      ) : (
        <>
          <div className={`row mb-3 d-lg-flex d-none ${style.sortingRow}`}>
            {!currencyList.length ? (
              ""
            ) : (
              <div
                className={`${style.currencyList} d-lg-block d-none col-lg-2`}
              >
                <Dropdown
                  type="currency"
                  options={currenyOptions}
                  selected={currency.name}
                  updateSelection={updateCurrency}
                ></Dropdown>
              </div>
            )}

            <div
              className={`ming  text-end ${
                style.sortButton
              } d-lg-block d-none col-lg-2 ${
                !currencyList.length ? style.noCurrency : ""
              }`}
            >
              <Dropdown
                type="sort"
                text={premiumsLabel}
                options={sortOptions}
                updateSelection={updateSorting}
                selected={sort}
              />
            </div>
            <div
              className={`ming text-end ${
                style.divideByButton
              } d-lg-block d-none col-lg-2 ${lang === "fr" ? style.fr : ""}`}
            >
              <Dropdown
                type="plain"
                // text={premiumsLabel}
                options={premiumByOptions}
                updateSelection={updateDivideBy}
                selected={premiumDivideBy}
              />
            </div>
            {/* <Dropdown type="sort" text={mdiSwapVertical} /> */}
          </div>
          <div className={`row `}>
            <div className={` ${style.plans} ps-lg-3 px-2 d-lg-block d-flex`}>
              {(loading || limit === 0) && <Skeleton />}
              {!loading && !plansSearched.length && (
                <div
                  className={`ming text-center openSans-Bold ${style.noResult}`}
                >
                  {noResultLabel}
                </div>
              )}
              {!loading &&
                plansSearched
                  .sort((a, b) => (a.annualPremium - b.annualPremium) * sort)
                  .slice(0, limit)
                  .map((item, index) => (
                    <BigPlanCard
                      isChecked={isPlanComparing(item)}
                      isCheckable={plansForComparing.length !== 3}
                      updateCompare={updateCompare}
                      plan={item}
                      proceedFurther={proceedFurther}
                      event={updateDetailCard}
                      labels={labels}
                      key={index}
                      currency={currency}
                      premiumDivideBy={premiumDivideBy}
                      openCalendly={openCalendly}
                      getAdviceCta={getAdviceCta}
                    />
                  ))}
              {limit < plansSearched.length && (
                <div className={`mt-5 text-center d-lg-block d-none`}>
                  <Button
                    type="secondary"
                    text={showMoreLabel}
                    event={loadMorePlans}
                  />
                </div>
              )}
            </div>
          </div>
          {limit < plansSearched.length && (
            <div className={`mt-5 text-center d-lg-none d-block`}>
              <Button
                type="secondary"
                text={showMoreLabel}
                event={loadMorePlans}
              />
            </div>
          )}
          <div className={`row ${style.disclaimer} italic`}>{disclaimer}</div>
        </>
      )}
      {/* mobile compare btn */}
      {/* <div className={`${style.floatingBtn}  d-lg-none d-block`}>
        <Button
          type={"primary"}
          text={compareLabel}
          event={comparePlans}
          isDisable={plansForComparing.length <= 1}
          buttonId={"mobile_compare_results_page_compare"}
        />
      </div> */}
      {/* desktop compare bar */}
      {/* <CompareBar
        plansForComparing={plansForComparing}
        updateCompare={updateCompare}
        getAdviceCta={getAdviceCta}
        openCalendly={openCalendly}
        compareLabel={compareLabel}
        planLabel={planLabel}
        comparePlans={comparePlans}
      /> */}
    </div>
  )
}
export default StepSearch

import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { benefitId } from "../../enumerator/benefitId"
import { staticText } from "../../enumerator/staticText"
import { getGatsbyImage, numberWithCommas } from "../../plugins/common"
import BenefitIcon from "./BenefitIcon"
import * as style from "./ComparisonTable.module.scss"

const ComparisonTable = props => {
  const { plans, labels, currency, premiumDivideBy } = props
  const { comparisonTable, perMonthLabel, perYearLabel } = labels
  const {
    insurerLabel,
    priceLabel,
    planLabel,
    inpatientLabel,
    outpatientLabel,
    maternityLabel,
    dentalLabel,
    opticalLabel,
  } = comparisonTable

  // const usdLabel = staticText.USD

  return (
    <div className={`${style.comparisonTable} container px-0 d-flex`}>
      <div className={`${style.firstCol}`}>
        <div className={`${style.header}`}>
          <div className={`${style.cell}`}>{insurerLabel}</div>
          <div className={`${style.cell}`}>{priceLabel}</div>
          <div className={`${style.cell}`}>{planLabel}</div>
        </div>
        <div className={`${style.body}`}>
          <div className={`${style.cell}`}>{inpatientLabel}</div>
          <div className={`${style.cell}`}>{outpatientLabel}</div>
          <div className={`${style.cell}`}>{maternityLabel}</div>
          <div className={`${style.cell}`}>{dentalLabel}</div>
          <div className={`${style.cell}`}>{opticalLabel}</div>
        </div>
      </div>
      <div className={`overflow-scroll d-flex`}>
        {plans.map((plan, index) => (
          <div className={`${style.dataCol}`} key={index}>
            <div className={`${style.header}`}>
              <div className={`${style.cell}`}>
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className={`${style.logo} flex-1`}
                  image={getGatsbyImage(plan.logo)}
                  alt="alea"
                />
              </div>
              <div className={`${style.cell}`}>
                <span>
                  {currency.symbol + " "}
                  {/* {usdLabel === staticText.USD ? usdLabel + " " : ""} */}
                  <span className={style.price}>
                    {/* {numberWithCommas(plan.annualPremium)} */}
                    {numberWithCommas(
                      Math.round(plan.annualPremium / premiumDivideBy)
                    )}
                  </span>
                  {premiumDivideBy === 12 ? perMonthLabel : perYearLabel}
                  {/* {usdLabel !== staticText.USD ? usdLabel : ""} */}
                </span>
              </div>
              <div className={`${style.cell}`}>
                <div className={`${style.title}`}>{plan.planName}</div>
                <div>{plan.coverageLevel}</div>
              </div>
            </div>
            <div className={`${style.body}`}>
              <div className={`${style.cell}`}>
                <BenefitIcon
                  id={benefitId.IN_PATIENT}
                  included={
                    plan.benefits.find(
                      b => b.pipedriveId === benefitId.IN_PATIENT
                    )?.included
                  }
                  inlineStyle={{ width: "30px", verticleAlign: "middle" }}
                  isLocal={true}
                />
              </div>
              <div className={`${style.cell}`}>
                <BenefitIcon
                  id={benefitId.OUT_PATIENT}
                  included={
                    plan.benefits.find(
                      b => b.pipedriveId === benefitId.OUT_PATIENT
                    )?.included
                  }
                  inlineStyle={{ width: "30px", verticleAlign: "middle" }}
                  isLocal={true}
                />
              </div>
              <div className={`${style.cell}`}>
                <BenefitIcon
                  id={benefitId.MATERNITY}
                  included={
                    plan.benefits.find(
                      b => b.pipedriveId === benefitId.MATERNITY
                    )?.included
                  }
                  inlineStyle={{ width: "30px", verticleAlign: "middle" }}
                  isLocal={true}
                />
              </div>
              <div className={`${style.cell}`}>
                <BenefitIcon
                  id={benefitId.DENTAL}
                  included={
                    plan.benefits.find(b => b.pipedriveId === benefitId.DENTAL)
                      ?.included
                  }
                  inlineStyle={{ width: "30px", verticleAlign: "middle" }}
                  isLocal={true}
                />
              </div>
              <div className={`${style.cell}`}>
                <BenefitIcon
                  id={benefitId.OPTICAL}
                  included={
                    plan.benefits.find(b => b.pipedriveId === benefitId.OPTICAL)
                      ?.included
                  }
                  inlineStyle={{ width: "30px", verticleAlign: "middle" }}
                  isLocal={true}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ComparisonTable

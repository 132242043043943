// extracted by mini-css-extract-plugin
export var alertRed = "BigPlanCard-module--alert-red--sbCUc";
export var applyBtn = "BigPlanCard-module--applyBtn--Kl9AY";
export var benefit = "BigPlanCard-module--benefit--EK3Kh";
export var benefitDetails = "BigPlanCard-module--benefitDetails--GiQ-q";
export var benefitIcon = "BigPlanCard-module--benefitIcon--i881g";
export var benefitIcon2 = "BigPlanCard-module--benefitIcon2--m2DwU";
export var benefitList = "BigPlanCard-module--benefitList--ddpwB";
export var benefitRow = "BigPlanCard-module--benefitRow--xJUl5";
export var bgBarGrey = "BigPlanCard-module--bg-bar-grey--6D5zN";
export var bgBeige = "BigPlanCard-module--bg-beige--43hx+";
export var bgCopper = "BigPlanCard-module--bg-copper--xZ1+x";
export var bgDaffodil = "BigPlanCard-module--bg-daffodil--fKif9";
export var bgGreyText = "BigPlanCard-module--bg-grey-text--RuMBL";
export var bgMing = "BigPlanCard-module--bg-ming--3UGEs";
export var bgMint = "BigPlanCard-module--bg-mint--sHYTm";
export var bgRed = "BigPlanCard-module--bg-red--Vozl7";
export var bgRedTrans = "BigPlanCard-module--bg-red-trans--pPSPJ";
export var bgSand = "BigPlanCard-module--bg-sand--DILuV";
export var bgSoftSun = "BigPlanCard-module--bg-softSun--EO0Zb";
export var bgTeal = "BigPlanCard-module--bg-teal--LibRk";
export var bgWhite = "BigPlanCard-module--bg-white--eAD1w";
export var bgWomenSharp = "BigPlanCard-module--bg-women-sharp--9zPEB";
export var bigCardPlan = "BigPlanCard-module--bigCardPlan--9VtVl";
export var bigcardCenter = "BigPlanCard-module--bigcardCenter--0G+dK";
export var bigcardRight = "BigPlanCard-module--bigcardRight--CjTpM";
export var blackText = "BigPlanCard-module--black-text--Imf4H";
export var checkbox = "BigPlanCard-module--checkbox--7GSNh";
export var checked = "BigPlanCard-module--checked--TxlXc";
export var copper = "BigPlanCard-module--copper--wk9uZ";
export var corners = "BigPlanCard-module--corners--9oqgA";
export var cursorNormal = "BigPlanCard-module--cursor-normal--BXUD2";
export var cursorPointer = "BigPlanCard-module--cursor-pointer--nt8fg";
export var dUnset = "BigPlanCard-module--d-unset--EIA6d";
export var darkGrey = "BigPlanCard-module--dark-grey--n+ik2";
export var dash = "BigPlanCard-module--dash--gtsUw";
export var flex = "BigPlanCard-module--flex--r9dkv";
export var flex1 = "BigPlanCard-module--flex-1--ybGrA";
export var fontSize12 = "BigPlanCard-module--font-size-12--2EAfO";
export var fontSize20 = "BigPlanCard-module--font-size-20--Mmq0Z";
export var greyPlaceholder = "BigPlanCard-module--grey-placeholder--KgBut";
export var greyText = "BigPlanCard-module--grey-text--kmcEH";
export var h1sizing = "BigPlanCard-module--h1sizing--IelQs";
export var h2sizing = "BigPlanCard-module--h2sizing--4daaU";
export var hidden = "BigPlanCard-module--hidden--HCA-+";
export var imageAni = "BigPlanCard-module--imageAni--a9RiY";
export var imageZoom = "BigPlanCard-module--imageZoom--j25bw";
export var imageZoomGatsby = "BigPlanCard-module--imageZoomGatsby--2AsaP";
export var infoRow = "BigPlanCard-module--infoRow--665PJ";
export var italic = "BigPlanCard-module--italic--HmnVW";
export var leftUpper = "BigPlanCard-module--leftUpper--CyRLY";
export var lightGrey = "BigPlanCard-module--light-grey--30Oud";
export var logo = "BigPlanCard-module--logo--KXrvY";
export var logoWrapper = "BigPlanCard-module--logoWrapper--OztBA";
export var lora = "BigPlanCard-module--lora--dXa6m";
export var loraBold = "BigPlanCard-module--lora-Bold--eea0R";
export var loraBoldItalic = "BigPlanCard-module--lora-BoldItalic--RYMEa";
export var loraMedium = "BigPlanCard-module--lora-Medium--F7+Ri";
export var loraSemiBold = "BigPlanCard-module--lora-SemiBold--q2jIL";
export var main = "BigPlanCard-module--main--vf4HY";
export var ming = "BigPlanCard-module--ming--19FMP";
export var mingHover2 = "BigPlanCard-module--ming-hover-2--nfM2W";
export var modalOpen = "BigPlanCard-module--modal-open--St0RK";
export var noUnderline = "BigPlanCard-module--no-underline--aV9LS";
export var openSans = "BigPlanCard-module--openSans--eodXo";
export var openSansBold = "BigPlanCard-module--openSans-Bold--HjeAy";
export var openSansSemiBold = "BigPlanCard-module--openSans-SemiBold--I3QoM";
export var planName = "BigPlanCard-module--planName--GvPLs";
export var premium = "BigPlanCard-module--premium--Xgnxr";
export var price = "BigPlanCard-module--price--ms0mA";
export var priceInfo = "BigPlanCard-module--priceInfo--e1BpG";
export var quotation = "BigPlanCard-module--quotation--RZpAQ";
export var round = "BigPlanCard-module--round--pe5Vx";
export var roundCorners = "BigPlanCard-module--round-corners--8AWh2";
export var softCorners = "BigPlanCard-module--soft-corners--6aRYC";
export var softShadows = "BigPlanCard-module--soft-shadows--bgj14";
export var softerCorners = "BigPlanCard-module--softer-corners--m54oi";
export var softyCorners = "BigPlanCard-module--softy-corners--FNwou";
export var tableShadow = "BigPlanCard-module--table-shadow--elruE";
export var teal = "BigPlanCard-module--teal--xSlFl";
export var topNavShadow = "BigPlanCard-module--top-nav-shadow--4P90b";
export var uncheckable = "BigPlanCard-module--uncheckable--nuJgH";
export var uncheckableBox = "BigPlanCard-module--uncheckableBox--T3tvm";
export var uppercase = "BigPlanCard-module--uppercase--gJvjd";
export var wFull = "BigPlanCard-module--w-full--7-gVK";
export var white = "BigPlanCard-module--white--RMMo0";
export var womenRed = "BigPlanCard-module--women-red--QEIp9";
export var womenSharp = "BigPlanCard-module--women-sharp--FpIKw";
const updateMeta = (
  searchParams,
  meta,
  setHeaderTitle,
  insuranceCompanyList,
  setInsuranceCompanyFilter,
  benefitList,
  setBenefitsFilter,
  noMetaIndex,
  setNoMetaIndex,
  dynamicMetaTitle,
  dynamicMetaDescription,
  dynamicHeaderTitle
) => {
  const { hasAllInList } = require("./common")

  let predefinedBenefits = null
  let predefinedCompany = null
  if (searchParams.length) {
    const benefitFilterTag = "filterByBenefit"
    const companyFilterTag = "filterByCompany"
    const prefilters = searchParams.split("&")
    prefilters.map(param => {
      const filter = param.split("=")
      if (filter[0] === benefitFilterTag) {
        predefinedBenefits = filter[1].split("|")
      } else if (filter[0] === companyFilterTag) {
        predefinedCompany = filter[1].split("|")
      }
    })
  }
  //find correct case
  if (predefinedBenefits || predefinedCompany) {
    let key = null
    if (!predefinedCompany && predefinedBenefits?.length === 1) key = "a"
    else if (
      predefinedCompany?.length === 1 &&
      predefinedBenefits?.length === 1
    )
      key = "b"
    else if (predefinedCompany?.length === 2 && !predefinedBenefits) key = "c"
    else if (
      predefinedCompany?.length === 2 &&
      predefinedBenefits?.length === 1
    )
      key = "d"
    else if (predefinedCompany?.length === 1 && !predefinedBenefits) key = "e"
    else if (
      predefinedCompany?.length === 2 &&
      predefinedBenefits?.length === 2
    ) {
      key = "f"
      setNoMetaIndex(true)
    }

    if (key) {
      //check if all params correct
      let validCompany = true
      let validBenefit = true
      if (key !== "a") {
        validCompany = hasAllInList(
          predefinedCompany,
          insuranceCompanyList.map(c => c.name)
        )
      }
      if (key !== "c" && key !== "e") {
        validBenefit = hasAllInList(
          predefinedBenefits,
          benefitList.map(c => c.name)
        )
      }

      if (validCompany && validBenefit) {
        // add corresponding filters
        if (key !== "a") {
          const insuranceCompanyListTemp = insuranceCompanyList.map(
            filterCompany => selectPredefined(predefinedCompany, filterCompany)
          )
          setInsuranceCompanyFilter(insuranceCompanyListTemp)
        }

        if (key !== "c" && key !== "e") {
          const benefitListTemp = benefitList.map(filterBenefit =>
            selectPredefined(predefinedBenefits, filterBenefit)
          )
          setBenefitsFilter(benefitListTemp)
        }

        //update meta and title except for case F
        if (!noMetaIndex) {
          let metaTitle = dynamicMetaTitle[key]
          let metaDescription = dynamicMetaDescription[key]
          let h1 = dynamicHeaderTitle[key]
          if (key !== "c" && key !== "e") {
            metaTitle = metaTitle.replace("{Benefit}", predefinedBenefits[0])
            metaDescription = metaDescription
              .split("{Benefit}")
              .join(predefinedBenefits[0])
            h1 = h1.replace("{Benefit}", predefinedBenefits[0])
          }
          if (key !== "a") {
            metaTitle = metaTitle.replace("{Company}", predefinedCompany[0])
            metaDescription = metaDescription.replace(
              "{Company}",
              predefinedCompany[0]
            )
            h1 = h1.replace("{Company}", predefinedCompany[0])

            if (key === "c" || key === "d") {
              metaTitle = metaTitle.replace("{Company}", predefinedCompany[1])
              metaDescription = metaDescription.replace(
                "{Company}",
                predefinedCompany[1]
              )
              h1 = h1.replace("{Company}", predefinedCompany[1])
            }
          }
          meta.title = metaTitle
          meta.description = metaDescription
          // headerTitle = h1
          setHeaderTitle(h1)
        }
      }
    }
  }
}

const selectPredefined = (predefinedList, obj) => {
  if (predefinedList.includes(obj.name)) obj.isSelected = true
  else obj.isSelected = false
  return obj
}
module.exports = {
  updateMeta,
}

import * as style from "./Skeleton.module.scss"
import React from "react"
const Skeleton = () => {
  const cardSkeleton = i => (
    <li key={i} className={`ps-0  ${style.o_media}`}>
      <div className={`ms-0 ${style.o_media__body}`}>
        <span className={`${style.skeleton_box}`}></span>
      </div>
    </li>
  )

  const numOfSkeleton = 6

  const allSkeleton = () => {
    let all = []
    for (let i = 0; i < numOfSkeleton; i++) {
      all.push(cardSkeleton(i))
    }
    return all
  }

  return (
    <div>
      <ul
        className={`ps-0 ${style.o_vertical_spacing} ${style.o_vertical_spacing__l}`}
      >
        {allSkeleton()}
      </ul>
    </div>
  )
}

export default Skeleton

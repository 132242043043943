// extracted by mini-css-extract-plugin
export var alertRed = "ComparisonTable-module--alert-red--P8ecp";
export var bgBarGrey = "ComparisonTable-module--bg-bar-grey--t9Y8j";
export var bgBeige = "ComparisonTable-module--bg-beige--ySl2D";
export var bgCopper = "ComparisonTable-module--bg-copper--YHDBk";
export var bgDaffodil = "ComparisonTable-module--bg-daffodil--mCi8T";
export var bgGreyText = "ComparisonTable-module--bg-grey-text--dHZ16";
export var bgMing = "ComparisonTable-module--bg-ming--Ns0Ar";
export var bgMint = "ComparisonTable-module--bg-mint--slcHm";
export var bgRed = "ComparisonTable-module--bg-red--5TIiy";
export var bgRedTrans = "ComparisonTable-module--bg-red-trans--bNYIF";
export var bgSand = "ComparisonTable-module--bg-sand--vh2Fo";
export var bgSoftSun = "ComparisonTable-module--bg-softSun--l5DKC";
export var bgTeal = "ComparisonTable-module--bg-teal--rnfOz";
export var bgWhite = "ComparisonTable-module--bg-white--olLKy";
export var bgWomenSharp = "ComparisonTable-module--bg-women-sharp--9H3oT";
export var blackText = "ComparisonTable-module--black-text--taOvv";
export var body = "ComparisonTable-module--body--gXrRN";
export var cell = "ComparisonTable-module--cell--X7SHk";
export var comparisonTable = "ComparisonTable-module--comparisonTable--P0a8E";
export var copper = "ComparisonTable-module--copper--swTGS";
export var corners = "ComparisonTable-module--corners---4yHh";
export var cursorNormal = "ComparisonTable-module--cursor-normal--R2diJ";
export var cursorPointer = "ComparisonTable-module--cursor-pointer--2Y0Ny";
export var dUnset = "ComparisonTable-module--d-unset--2LJ4A";
export var darkGrey = "ComparisonTable-module--dark-grey--LxUep";
export var dash = "ComparisonTable-module--dash--z1s+O";
export var dataCol = "ComparisonTable-module--dataCol--pbiMU";
export var firstCol = "ComparisonTable-module--firstCol--l2chp";
export var flex = "ComparisonTable-module--flex--mE+Wl";
export var flex1 = "ComparisonTable-module--flex-1--AqMal";
export var fontSize12 = "ComparisonTable-module--font-size-12--I-oWz";
export var fontSize20 = "ComparisonTable-module--font-size-20--Icvtu";
export var greyPlaceholder = "ComparisonTable-module--grey-placeholder--WQwZa";
export var greyText = "ComparisonTable-module--grey-text--FY+8R";
export var h1sizing = "ComparisonTable-module--h1sizing--6X2Qx";
export var h2sizing = "ComparisonTable-module--h2sizing--1+o39";
export var header = "ComparisonTable-module--header--WS1xs";
export var hidden = "ComparisonTable-module--hidden--tIbN9";
export var imageAni = "ComparisonTable-module--imageAni--Hif4K";
export var imageZoom = "ComparisonTable-module--imageZoom--G9Zv0";
export var imageZoomGatsby = "ComparisonTable-module--imageZoomGatsby--N5nqo";
export var italic = "ComparisonTable-module--italic--TMcUH";
export var lightGrey = "ComparisonTable-module--light-grey--bX9VR";
export var logo = "ComparisonTable-module--logo--RQFs-";
export var lora = "ComparisonTable-module--lora--Qk03w";
export var loraBold = "ComparisonTable-module--lora-Bold--v3FeZ";
export var loraBoldItalic = "ComparisonTable-module--lora-BoldItalic--zaftC";
export var loraMedium = "ComparisonTable-module--lora-Medium--a7zLd";
export var loraSemiBold = "ComparisonTable-module--lora-SemiBold--1FS54";
export var main = "ComparisonTable-module--main--pj6eO";
export var ming = "ComparisonTable-module--ming--n5Q3n";
export var mingHover2 = "ComparisonTable-module--ming-hover-2--KjKdB";
export var modalOpen = "ComparisonTable-module--modal-open--A51js";
export var noUnderline = "ComparisonTable-module--no-underline--iJc9i";
export var openSans = "ComparisonTable-module--openSans--LEWBl";
export var openSansBold = "ComparisonTable-module--openSans-Bold--cd2qA";
export var openSansSemiBold = "ComparisonTable-module--openSans-SemiBold--7gCHc";
export var price = "ComparisonTable-module--price--jBtP0";
export var quotation = "ComparisonTable-module--quotation--Fv3Qf";
export var round = "ComparisonTable-module--round--xdii6";
export var roundCorners = "ComparisonTable-module--round-corners--bFULd";
export var softCorners = "ComparisonTable-module--soft-corners--u9lG9";
export var softShadows = "ComparisonTable-module--soft-shadows--XY7sl";
export var softerCorners = "ComparisonTable-module--softer-corners--+j8mr";
export var softyCorners = "ComparisonTable-module--softy-corners--1KWPr";
export var tableShadow = "ComparisonTable-module--table-shadow--Qt3Wq";
export var teal = "ComparisonTable-module--teal--sNGo8";
export var title = "ComparisonTable-module--title--AwVod";
export var topNavShadow = "ComparisonTable-module--top-nav-shadow--wviH9";
export var uppercase = "ComparisonTable-module--uppercase--5bO4t";
export var wFull = "ComparisonTable-module--w-full--y22nL";
export var white = "ComparisonTable-module--white--IIiMD";
export var womenRed = "ComparisonTable-module--women-red--MH+bp";
export var womenSharp = "ComparisonTable-module--women-sharp--O6pbh";
import React from "react"
import Button from "../Button"
import * as style from "./ToolBottom.module.scss"

const ToolBottom = ({ item, lang }) => {
  const { title, subtitle, ctas } = item
  return (
    <div className={`${style.toolBottom}`}>
      <h2 className={`ming ${style.title} text-center mb-5`}>{title}</h2>
      <div className={`lora ${style.subtitle} text-center mb-5`}>
        {subtitle}
      </div>
      <div className={`flex justify-content-center flex-lg-row flex-column`}>
        {ctas &&
          ctas.slice(0, 2).map((item, index) => {
            return (
              <div
                key={`eqt-btn-${index}`}
                className={`mx-lg-5 mx-0 align-self-center mb-3`}
              >
                <Button
                  lang={lang}
                  type={item.template}
                  text={item.buttonLabel}
                  url={item.url}
                  buttonId={
                    item.name.toLowerCase().includes("quote")
                      ? "eqt_bottom_get_free_quote"
                      : "eqt_bottom_book_call"
                  }
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default ToolBottom

import * as style from "./BigPlanCard.module.scss"

import React from "react"
import {
  // mdiCheckboxBlankOutline,
  // mdiCheckboxMarked,
  // mdiCheckboxBlank,
  mdiCheckCircleOutline,
} from "@mdi/js"
import Icon from "@mdi/react"
import Button from "../Button"
import { numberWithCommas, getGatsbyImage } from "../../plugins/common"
import { quotationType } from "../../enumerator/quotationType"
import BenefitIcon from "./BenefitIcon"
import { GatsbyImage } from "gatsby-plugin-image"
// import { staticText } from "../../enumerator/staticText"

const BigPlanCard = props => {
  const {
    isChecked,
    // isCheckable,
    // updateCompare,
    plan,
    proceedFurther,
    event,
    labels,
    currency,
    premiumDivideBy,
    openCalendly,
    getAdviceCta,
  } = props

  const {
    perYearLabel,
    perMonthLabel,
    applyLabel,
    // compareLabel,
    moreDetailsLabel,
  } = labels
  // const usdLabel = staticText.USD

  const companyLogo = getGatsbyImage(plan.logo)

  const applyPlan = () => proceedFurther(plan, quotationType.APPLY)
  const viewMoreDetails = () => event(plan)

  // const checkThisPlan = () => {
  //   updateCompare(plan)
  // }

  return (
    <div
      className={`${style.bigCardPlan} soft-corners container ${
        isChecked ? style.checked : ""
      }`}
      // onClick={() => event(plan)}
      // onKeyDown={() => event(plan)}
      // role="button"
      // tabIndex={0}
    >
      <div className={`row`}>
        <div className={`col-lg-3 col-12 d-flex flex-lg-column flex-row `}>
          <div
            className={`${style.leftUpper} flex  flex-lg-column flex-row justify-content-center`}
          >
            <div
              className={`col-lg-12 col-12 flex flex-lg-column flex-row ${style.infoRow} px-lg-0 px-2 my-lg-0 my-2`}
            >
              {/* logo */}
              <div
                className={`${style.logoWrapper} text-center col-lg-12 col-3`}
              >
                {/* <img src={plan.logo} className={`${style.logo} flex-1`} /> */}
                <GatsbyImage
                  sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                  className={`${style.logo} flex-1`}
                  image={companyLogo}
                  alt="alea"
                />
              </div>
              <div className={` col-lg-12 col-9 align-self-center`}>
                <div
                  className={`${style.planName} mt-lg-3 mb-1 ming openSans-Bold`}
                >
                  {plan.coverageLevel}
                </div>
                <div className={`${style.planName} ming  openSans-SemiBold`}>
                  {plan.planName}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={` col-lg-6 col-12 ${style.bigcardCenter}  align-self-center`}
        >
          <div
            className={`${style.benefitRow} d-lg-none d-block offset-0 d-flex justify-content-between mx-lg-0 mx-2`}
          >
            {plan.benefits.map((item, index) => (
              <span key={index}>
                <span className={`d-lg-block d-none`}>
                  <BenefitIcon
                    id={item.pipedriveId}
                    included={item.included}
                    inlineStyle={{ width: "40px" }}
                  />
                </span>
                <span className={`d-lg-none d-block`}>
                  <BenefitIcon
                    id={item.pipedriveId}
                    included={item.included}
                    inlineStyle={{ width: "30px" }}
                  />
                </span>
              </span>
            ))}
          </div>
          <div
            className={`d-none d-lg-flex flex-column row ${style.benefitList} overflow-auto`}
          >
            {plan.benefits.map(
              (ben, index) =>
                ben.included && (
                  <div
                    className={`flex col-lg-6 mb-3 ${style.benefit}`}
                    key={index}
                  >
                    <div className={`mt-1 me-2`}>
                      <BenefitIcon
                        id={ben.pipedriveId}
                        included={ben.included}
                        inlineStyle={{ width: "32px" }}
                      />
                      {/* <span className={`${style.title} ms-2`}>{ben.name}</span> */}
                    </div>
                    <div className={`${style.benefitDetails}`}>
                      {ben.details.map((detail, index) => (
                        <div key={index}>
                          <Icon
                            className={`teal`}
                            path={mdiCheckCircleOutline}
                            size={1}
                          />
                          <span className={`openSans-SemiBold`}>{detail}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
        <div
          className={`col-lg-3 align-self-center px-0 text-center ${style.bigcardRight}`}
        >
          {/* {usdLabel === staticText.USD ? usdLabel + " " : ""} */}
          <div
            className={`flex flex-lg-column flex-row align-items-baseline justify-content-center my-lg-0 mt-2 mb-3`}
          >
            <div
              className={`${style.price} openSans-Bold ming mb-lg-1 mr-lg-0 mr-2 align-self-lg-center`}
            >
              {numberWithCommas(
                Math.round(plan.annualPremium / premiumDivideBy)
              )}
            </div>
            <div
              className={`${style.priceInfo} openSans-Bold ming mb-lg-3 align-self-lg-center`}
            >
              {currency.symbol + " "}
              {premiumDivideBy === 12 ? perMonthLabel : perYearLabel}
            </div>
          </div>
          <div className={`${style.applyBtn} d-block d-lg-none my-2`}>
            <Button
              type="invertedTertiary"
              text={moreDetailsLabel}
              event={viewMoreDetails}
              stopPropagation={true}
              // buttonId={"compare_results_page_free_quote_" + plan.id}
            />
          </div>
          <div className={`${style.applyBtn} mb-2`}>
            <Button
              type="primary"
              text={applyLabel}
              event={applyPlan}
              stopPropagation={true}
              buttonId={"compare_results_page_free_quote_" + plan.id}
            />
          </div>
          <div className={`${style.applyBtn}`}>
            <Button
              type="secondary"
              text={getAdviceCta.buttonLabel}
              event={openCalendly}
              stopPropagation={true}
              // buttonId={"compare_results_page_free_quote_" + plan.id}
            />
          </div>
          {/* compare checkbox */}
          {/* <div className={` flex justify-content-center mt-2`}>
            <span className={`black-text  openSans-Bold me-2`}>
              {compareLabel}
            </span>
            <div
              className={`${style.checkbox} ${
                !isChecked && !isCheckable ? style.uncheckable : ""
              }`}
              onClick={checkEvent => {
                checkThisPlan()
                if (!checkEvent) checkEvent = window.event
                checkEvent.cancelBubble = true
                if (checkEvent.stopPropagation) checkEvent.stopPropagation()
              }}
              onKeyDown={checkEvent => {
                checkThisPlan()
                if (!checkEvent) checkEvent = window.event
                checkEvent.cancelBubble = true
                if (checkEvent.stopPropagation) checkEvent.stopPropagation()
              }}
              role="button"
              tabIndex={-2}
            >
              {isChecked ? (
                <Icon className={` ming`} path={mdiCheckboxMarked} size={1} />
              ) : isCheckable ? (
                <Icon
                  className={`grey-text`}
                  path={mdiCheckboxBlankOutline}
                  size={1}
                />
              ) : (
                <Icon
                  className={`grey-text ${style.uncheckableBox}`}
                  path={mdiCheckboxBlank}
                  size={1}
                />
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
export default BigPlanCard

import * as style from "./Apply.module.scss"

import React, { useState, useEffect, useRef } from "react"
import QuotationForm from "./Form"
import SmallPlanCard from "./SmallPlanCard"
import Button from "../Button"
import { toPng } from "html-to-image"
import { formValidation } from "../../plugins/common"
import ComparisonTable from "./ComparisonTable"

const QuotationApply = props => {
  const {
    form,
    plans,
    isHorizontal,
    isCompare,
    updateStep,
    location,
    submitForm,
    labels,
    errorMessage,
    setErrorMessage,
    setSubmitLoading,
    currency,
    premiumDivideBy,
  } = props

  const {
    comparisonTable,
    formLabels,
    additionalOptionsLabel,
    backLabel,
    submitLabel,
    applyNowLabel,
    applySubtitle,
    compareSubtitle,
    recieveSubtitle,
    usdLabel,
    perYearLabel,
    perMonthLabel,
  } = labels

  const invalidFormMsg = formLabels.invalidFormMsg
  const nextLabel = isHorizontal ? applyNowLabel : submitLabel
  const submitBtnId = isHorizontal
    ? "compare_results_page_get_your_quote"
    : isCompare
    ? "compare_results_page_compare_submit"
    : "compare_results_page_receive_details_submit"

  const defaultFormError = {
    firstName: false,
    familyName: false,
    phone: false,
    email: false,
  }

  const [formError, setFormError] = useState(defaultFormError)
  const goBack = () => updateStep(1)
  const winRef = useRef(null)

  useEffect(() => {
    winRef.current.scrollIntoView({ block: "start" })
  }, [])

  const cardRefs = useRef([])
  cardRefs.current = []
  const addToRefs = el => {
    if (el && !cardRefs.current.includes(el)) {
      cardRefs.current.push(el)
    }
  }
  const getCardsImage = async () => {
    return await Promise.all(
      cardRefs.current.map(async (ref, index) => {
        let base64 = ""
        try {
          base64 = await toPng(ref, { cacheBust: true })
        } catch (error) {
          console.log(error)
        }
        const name =
          plans[index].planName +
          "-" +
          plans[index].coverageLevel +
          "-" +
          plans[index].id +
          "-" +
          Date.now()
        return {
          name: name,
          dataUrl: base64,
          url: process.env.GATSBY_API_URL + "/uploads/eqt/" + name + ".png",
        }
      })
    )
  }

  const submit = async () => {
    setSubmitLoading(true)
    if (!formValidation(form, setFormError, setErrorMessage, invalidFormMsg)) {
      setSubmitLoading(false)
      return
    }
    const policyIds = plans.map(p => p.policyId)
    submitForm(await getCardsImage(), policyIds)
  }

  return (
    <div className={`${style.quotationApply} container ps-0`}>
      <span
        className={`position-absolute`}
        style={{ top: -50 }}
        ref={winRef}
      ></span>
      <div
        className={`position-absolute d-block d-lg-none`}
        style={{ top: "20px" }}
      >
        <Button type={`arrowLeft`} text={`${backLabel}`} event={goBack} />
      </div>
      {isHorizontal && (
        <h3 className={`ming d-lg-block d-none`}>{applySubtitle}</h3>
      )}
      <div className={`${isHorizontal ? "d-lg-flex" : "d-flex flex-column"}`}>
        <div
          className={`${
            isHorizontal
              ? "col-lg-5"
              : "d-flex align-self-center align-items-end my-4"
          } 
          ${isCompare ? "d-lg-flex" : ""}
          ${isCompare ? style.compareCards : ""}
          `}
        >
          {plans.map((plan, index) => (
            <SmallPlanCard
              forwardRef={addToRefs}
              id={`plan-${index}`}
              plan={plan}
              key={index}
              location={location}
              isCompare={isCompare}
              additionalOptionsLabel={additionalOptionsLabel}
              currency={currency}
              premiumDivideBy={premiumDivideBy}
              labels={labels}
            />
          ))}
        </div>
        <div className={`${isCompare ? "d-lg-none d-block" : "d-none"}`}>
          <ComparisonTable
            plans={plans}
            labels={labels}
            currency={currency}
            premiumDivideBy={premiumDivideBy}
          />
        </div>

        <h3 className={`ming d-block d-lg-none mt-5 mb-0`}>
          {isHorizontal
            ? applySubtitle
            : isCompare
            ? compareSubtitle
            : recieveSubtitle}
        </h3>
        <div
          className={`${
            isHorizontal ? "col-lg-6 align-self-center" : "mx-lg-5 mt-lg-5"
          }`}
        >
          {!isHorizontal && (
            <h3 className={`ming d-lg-block d-none`}>
              {isCompare ? compareSubtitle : recieveSubtitle}
            </h3>
          )}
          <div className={`alert-red openSans-Bold`}>{errorMessage}</div>
          <QuotationForm
            form={form}
            isHalf={isHorizontal}
            errors={formError}
            labels={formLabels}
          />
        </div>
      </div>
      <div
        className={`align-items-center justify-content-lg-end mt-5 d-flex flex-lg-row flex-column`}
      >
        <div className="order-lg-0 order-1">
          <Button type="secondary" text={backLabel} event={goBack} />
        </div>
        <div className="order-lg-1 order-0 ms-lg-4 mb-3 mb-lg-0">
          <Button
            type="primary"
            text={nextLabel}
            event={submit}
            buttonId={submitBtnId}
          />
        </div>
      </div>
    </div>
  )
}

export default QuotationApply

import React, { useRef, useState, useEffect } from "react"
import { gender } from "../../enumerator/gender"
import * as style from "./AddPerson.module.scss"
import { mdiMinusCircle } from "@mdi/js"
import DropdownV2 from "./DropdownV2"
import Switch from "../common/Switch"
import CustomIcon from "../CustomIcon"
import Icon from "@mdi/react"

const AddPerson = ({
  labels,
  additionInsurers,
  setAdditionInsurers,
  ageList,
}) => {
  const [opened, setOpened] = useState(false)
  const addPersonRef = useRef(null)

  const handleClickOutside = e => {
    if (
      (addPersonRef.current && !addPersonRef.current.contains(e.target)) ||
      e.target.id === "addPersonBoxWrapper"
    ) {
      setOpened(false)
    }
  }

  const addPerson = () => {
    const newList = [...additionInsurers, { age: 35, gender: gender.female }]
    setAdditionInsurers(newList)
  }
  const removeInsurer = index => {
    const newList = additionInsurers.filter((ad, id) => id !== index)
    setAdditionInsurers(newList)
  }

  const updateInsurersAge = (age, id) => {
    const newAddi = additionInsurers.map((ad, index) => {
      if (index === id) ad.age = age
      return ad
    })
    setAdditionInsurers(newAddi)
  }

  const updateInsurersGender = (gender, id) => {
    const newAddi = additionInsurers.map((ad, index) => {
      if (index === id) ad.gender = gender
      return ad
    })
    setAdditionInsurers(newAddi)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true)
    }
  })

  // const ageList = Array.from(Array(83).keys(), n => n + 18)

  return (
    <div ref={addPersonRef}>
      <div
        className={`${style.addPerson} flex align-items-center px-2 my-4 my-lg-0`}
        tabIndex={0}
        role="button"
        onClick={() => setOpened(!opened)}
        onKeyDown={() => setOpened(!opened)}
      >
        <span className={`teal label lora-Bold`}>{labels.addPersonLabel}</span>
        {additionInsurers.length > 0 && (
          <div
            className={`round ${style.ball} bg-teal white ms-3`}
          >{`+${additionInsurers.length}`}</div>
        )}
      </div>
      {opened && (
        <div
          id={`addPersonBoxWrapper`}
          className={`position-absolute d-relative ${style.addPersonBoxWrapper} mt-lg-2`}
        >
          <div className={`position-relative`}>
            <div
              role="button"
              tabIndex={0}
              className={`${style.closeBtn} d-lg-none d-block position-absolute`}
              onClick={() => setOpened(false)}
              onKeyDown={() => setOpened(false)}
            >
              <CustomIcon icon="close" className={`${style.closeIcon}`} />
            </div>
            <div
              className={`bg-daffodil ${style.addPersonBox} corners p-3 corners soft-shadows`}
            >
              <div className={`teal h4 lora-SemiBold text-center`}>
                {labels.addPersonTitle}
              </div>
              <div className={``}>
                {additionInsurers?.map((ins, index) => (
                  <div
                    key={index}
                    className={`flex align-items-center justify-content-between mb-2`}
                  >
                    <div className={`${style.number} lora-SemiBold col-2`}>
                      {index + 1}
                    </div>
                    <div className={` col-4`}>
                      <div className={` mb-2 lora-Bold`}>{labels.ageLabel}</div>
                      <div className={``}>
                        <DropdownV2
                          options={ageList}
                          parentIndex={index}
                          selected={ins.age}
                          setSelected={updateInsurersAge}
                          labels={labels}
                        />
                      </div>
                    </div>

                    <div className={`col-4`}>
                      <div className={` mb-2 lora-Bold`}>
                        {labels.genderLabel}
                      </div>
                      <div className={``}>
                        <Switch
                          optionA={{
                            name: labels.femaleLabel,
                            value: gender.female,
                          }}
                          optionB={{
                            name: labels.maleLabel,
                            value: gender.male,
                          }}
                          selected={ins.gender}
                          setSelected={updateInsurersGender}
                          parentIndex={index}
                          labels={labels}
                        />
                      </div>
                    </div>

                    <div
                      role="button"
                      tabIndex={0}
                      className={`col-1`}
                      onClick={() => removeInsurer(index)}
                      onKeyDown={() => removeInsurer(index)}
                    >
                      <Icon
                        path={mdiMinusCircle}
                        size={1}
                        className={`grey-text`}
                        // color={`grey`}
                        // className={`${style.closeIcon}`}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div
                tabIndex={-1}
                role="button"
                onClick={() => addPerson()}
                onKeyDown={() => addPerson()}
                className={`teal lora-SemiBold flex`}
              >
                <span className={`ms-5 mt-3 ${style.addPersonBtn} `}>
                  {labels.addPersonLabel}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AddPerson

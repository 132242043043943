import React from "react"
import "./Switch.scss"

const Switch = ({ optionA, optionB, selected, setSelected, parentIndex }) => {
  return (
    <div className={`switch flex bg-grey-text corners justify-content-between`}>
      <div
        tabIndex={0}
        role="button"
        onClick={() =>
          typeof parentIndex === "number"
            ? setSelected(optionA.value, parentIndex)
            : setSelected(optionA.value)
        }
        onKeyDown={() =>
          typeof parentIndex === "number"
            ? setSelected(optionA.value, parentIndex)
            : setSelected(optionA.value)
        }
        className={`switch-option p-1 corners  white ${
          selected === optionA.value ? "bg-teal" : "bg-grey-text"
        }`}
      >
        {optionA.name}
      </div>
      <div
        tabIndex={-1}
        role="button"
        onClick={() =>
          typeof parentIndex === "number"
            ? setSelected(optionB.value, parentIndex)
            : setSelected(optionB.value)
        }
        onKeyDown={() =>
          typeof parentIndex === "number"
            ? setSelected(optionB.value, parentIndex)
            : setSelected(optionB.value)
        }
        className={`switch-option p-1 corners  white ${
          selected === optionB.value ? "bg-teal" : "bg-grey-text "
        }`}
      >
        {optionB.name}
      </div>
    </div>
  )
}

export default Switch

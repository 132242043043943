import React, { useRef, useState, useEffect } from "react"
import "./DropdownV2.scss"
import {
  mdiTriangle,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
} from "@mdi/js"
import Icon from "@mdi/react"
import CustomIcon from "../CustomIcon"

const DropdownV2 = ({
  parentIndex,
  selectValue,
  options,
  updateOptions,
  selected,
  setSelected,
  className,
  selectAllLabel,
  clearAllLabel,
  multiSelect,
  labels,
  hideTriangle,
}) => {
  const { allLabel, selectedLabel } = labels ?? {}

  const [opened, setOpened] = useState(false)
  const [title, setTitle] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  const dropdownRef = useRef(null)

  const handleClickOutside = e => {
    if (
      (dropdownRef.current && !dropdownRef.current.contains(e.target)) ||
      e.target.id === "dropdown-list-wrapper"
    ) {
      setOpened(false)
    }
  }

  const updateSelection = index => {
    if (options[index].persist) return
    options[index].isSelected = !options[index].isSelected
    const newOptions = JSON.parse(JSON.stringify(options))
    updateOptions(newOptions)
  }
  const clearAll = () => {
    options.map(o => {
      if (!o.persist) o.isSelected = false
    })
    updateOptions(JSON.parse(JSON.stringify(options)))
  }
  const selectAll = () => {
    options.map(o => (o.isSelected = true))
    updateOptions(JSON.parse(JSON.stringify(options)))
  }

  useEffect(() => {
    if (!selected) {
      const totalSelected = options.filter(o => o.isSelected)?.length
      setTitle(
        totalSelected === options.length
          ? allLabel
          : `${totalSelected} ${selectedLabel}`
      )

      if (!totalSelected) setIsEmpty(true)
      else setIsEmpty(false)
    }
  }, [options, setTitle, setIsEmpty, allLabel, selected, selectedLabel])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true)
    }
  })

  return (
    <div ref={dropdownRef} className={`newDropdown relative`}>
      <div
        className={`dropdown-box ${className} ${
          isEmpty ? "bg-red" : "bg-teal"
        } white soft-corners text-center py-1 px-3 flex justify-content-between align-items-center`}
        tabIndex={0}
        role="button"
        onClick={() => setOpened(!opened)}
        onKeyDown={() => setOpened(!opened)}
      >
        {selected ?? title}
        {!hideTriangle && (
          <Icon
            path={mdiTriangle}
            size={0.7}
            color={`white`}
            className={`arrow ms-3 ${opened ? "" : "arrow-down"}`}
          />
        )}
      </div>
      {opened && (
        <div
          id={`dropdown-list-wrapper`}
          className={`position-absolute dropdown-list-wrapper`}
        >
          <div
            className={`dropdown-list ${
              selectValue ? "w-300" : ""
            } bg-white soft-corners position-relative`}
          >
            <div
              role="button"
              tabIndex={0}
              className={`closeBtn d-lg-none d-block position-absolute`}
              onClick={() => setOpened(false)}
              onKeyDown={() => setOpened(false)}
            >
              <CustomIcon icon="close" className={`closeIcon`} />
            </div>
            <div className={`p-lg-2 px-4 py-4`}>
              {multiSelect && (
                <div className={`flex mt-lg-0 my-3`}>
                  <div
                    className={`col-lg-6 text-center border copper btn me-1 d-grid`}
                    onClick={selectAll}
                    onKeyDown={selectAll}
                    tabIndex={-1}
                    role="button"
                  >
                    {selectAllLabel}
                  </div>
                  <div
                    className={`col-lg-6 text-center border copper btn d-grid`}
                    onClick={clearAll}
                    onKeyDown={clearAll}
                    tabIndex={-2}
                    role="button"
                  >
                    {clearAllLabel}
                  </div>
                </div>
              )}
              {options.map((op, index) => (
                <div
                  className={`${
                    op.persist ? "disable-option" : "option"
                  }  flex justify-content-between my-1 align-items-center`}
                  key={index}
                  tabIndex={index}
                  id={op.name}
                  onClick={() => {
                    if (setSelected) {
                      typeof parentIndex === "number"
                        ? setSelected(op, parentIndex)
                        : selectValue
                        ? setSelected(op.value)
                        : setSelected(op)
                      setOpened(false)
                    } else updateSelection(index)
                  }}
                  onKeyDown={() => {
                    if (setSelected) {
                      typeof parentIndex === "number"
                        ? setSelected(op, parentIndex)
                        : selectValue
                        ? setSelected(op.value)
                        : setSelected(op)
                      setOpened(false)
                    } else updateSelection(index)
                  }}
                  role="button"
                >
                  {selectValue ? (
                    <div className={`flex col-12`}>
                      <span className={`col-4`}>{op.value}</span>
                      <span className={`col-8`}>{op.name}</span>
                    </div>
                  ) : (
                    op.name ?? op
                  )}

                  {multiSelect && (
                    <Icon
                      className={`checkbox ms-2 ${
                        op.isSelected
                          ? op.id === "40"
                            ? "disable"
                            : "selected"
                          : " "
                      } `}
                      path={
                        op.isSelected
                          ? mdiCheckboxMarked
                          : mdiCheckboxBlankOutline
                      }
                      size={1}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DropdownV2

import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { benefitId } from "../../enumerator/benefitId"
import * as style from "./BenefitIcon.module.scss"

import localIconInpatient from "../../images/quotation/benefits/inpatient.png"
import localIconOutpatient from "../../images/quotation/benefits/outpatient.png"
import localIconMaternity from "../../images/quotation/benefits/maternity.png"
import localIconDental from "../../images/quotation/benefits/dental.png"
import localIconOptical from "../../images/quotation/benefits/optical.png"

const BenefitIcon = ({ id, included, inlineStyle, isLocal, isCard }) => {
  const images = [
    {
      name: benefitId.IN_PATIENT,
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefits/inpatient.png"
          alt="alea"
        />
      ),
      card: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefitCard/inpatient.png"
          alt="alea"
        />
      ),
      local: localIconInpatient,
    },
    {
      name: benefitId.OUT_PATIENT,
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefits/outpatient.png"
          alt="alea"
        />
      ),
      card: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefitCard/outpatient.png"
          alt="alea"
        />
      ),
      local: localIconOutpatient,
    },
    {
      name: benefitId.MATERNITY,
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefits/maternity.png"
          alt="alea"
        />
      ),
      card: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefitCard/maternity.png"
          alt="alea"
        />
      ),
      local: localIconMaternity,
    },
    {
      name: benefitId.DENTAL,
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefits/dental.png"
          alt="alea"
        />
      ),
      card: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefitCard/dental.png"
          alt="alea"
        />
      ),
      local: localIconDental,
    },
    {
      name: benefitId.OPTICAL,
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefits/optical.png"
          alt="alea"
        />
      ),
      card: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          style={inlineStyle}
          className={!included ? style.disable : ""}
          src="../../images/quotation/benefitCard/optical.png"
          alt="alea"
        />
      ),
      local: localIconOptical,
    },
  ]

  images.map(image => {
    image.gatbyLocal = (
      <img
        src={image.local}
        style={inlineStyle}
        className={!included ? style.disable : ""}
        alt="alea"
      />
    )
  })

  const image = images.find(f => f.name === id)
  if (!image) return <></>
  return isLocal ? image.gatbyLocal : isCard ? image.card : image.icon
}

export default BenefitIcon
